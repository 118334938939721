body {
  margin: 0;
 overflow: hidden;
}
html,
body {
  overscroll-behavior: none;
}
*{
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
}